import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import header from './modules/header'
import logo from './modules/logo'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import suscripciones from './modules/suscripciones'
import note from './modules/note'
import team from './modules/team'
import info from './modules/info'
import banner from './modules/banner'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    colors:colors,
    copy:copy,
    header:header,
    logo:logo,
    main: main,
    mainmtr:mainmtr,
    profile:profile,
    usuarios: usuarios,
    suscripciones:suscripciones,
    note:note,
    team:team,
    info:info,
    banner:banner
  
  }
})
